.table-delete {
    right: 5px;
    bottom: 15px;
    cursor: pointer;
    &:hover {
        color: red;
    }
}

.table-textarea {
    background-color: #EBF0F5;
}