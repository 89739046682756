.grid-delete {
    right: 5px;
    bottom: 15px;
    cursor: pointer;

    &:hover {
        color: #E31D19;
    }
}

.grid-email {
    right: 5px;
    bottom: 15px;
    cursor: pointer;

    &:hover {
        color: #0054ff;
    }
}

.grid-header {
    color: white;
    background-color: #007953;
}

.grid-btn-export-csv {
    color: white;
    background-color: #009063;

    &:hover {
        color: white;
        background-color: #00a16e;
    }

    &:active {
        color: white;
        background-color: #009063;
    }
}

.grid-btn-export {
    color: white;
    background-color: #005137;

    &:hover {
        color: white;
        background-color: #007b55;
    }
}