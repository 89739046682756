.buttonBox {
  display: flex;
}

.pull-left {
  float: left !important;
}

.pull-right {
  margin-left: auto !important;
  order: 2 !important;
}

.invisible {
  display: none !important;
}
