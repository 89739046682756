// Theme colors
#main-wrapper {
  &[data-layout='vertical'] {
    /*This is for the logo bg*/
    .topbar .top-navbar .navbar-header {
      &[data-logobg='skin1'] {
        background: $skin1;
      }

      &[data-logobg='skin2'] {
        background: $skin2;
      }

      &[data-logobg='skin3'] {
        background: $skin3;
      }

      &[data-logobg='skin4'] {
        background: $skin4;
      }

      &[data-logobg='skin5'] {
        background: $skin5;
      }

      &[data-logobg='skin6'] {
        background: $skin6;

        .navbar-brand {
          .dark-logo {
            display: inline;
          }

          .light-logo {
            display: none;
          }
        }

        .nav-toggler,
        .topbartoggler {
          color: $body-color;
        }
      }
    }
  }

  &[data-layout='vertical'],
  &[data-layout='horizontal'] {
    .topbar .navbar-collapse,
    .topbar {
      &[data-navbarbg='skin1'] {
        @extend %skin_gredient_1;
      }

      &[data-navbarbg='skin2'] {
        @extend %skin_gredient_2;
      }

      &[data-navbarbg='skin3'] {
        @extend %skin_gredient_3;
      }

      &[data-navbarbg='skin4'] {
        @extend %skin_gredient_4;
      }

      &[data-navbarbg='skin5'] {
        @extend %skin_gredient_5;
      }

      &[data-navbarbg='skin6'] {
        @extend %skin_gredient_6;
      }
    }

    /*This is for the sidebar bg*/
    .left-sidebar {
      &[data-sidebarbg='skin1'],
      &[data-sidebarbg='skin1'] ul {
        background: $skin1;

        .sidebar-footer {
          background: $skin1;
        }
      }

      &[data-sidebarbg='skin2'],
      &[data-sidebarbg='skin2'] ul {
        background: $skin2;

        .sidebar-footer {
          background: $skin2;
        }
      }

      &[data-sidebarbg='skin3'],
      &[data-sidebarbg='skin3'] ul {
        background: $skin3;

        .sidebar-footer {
          background: $skin3;
        }
      }

      &[data-sidebarbg='skin4'],
      &[data-sidebarbg='skin4'] ul {
        background: $skin4;

        .sidebar-footer {
          background: $skin4;
        }
      }

      &[data-sidebarbg='skin5'],
      &[data-sidebarbg='skin5'] ul {
        background: $skin5;

        .sidebar-footer {
          background: $skin5;
        }
      }

      &[data-sidebarbg='skin6'] {
        background: $skin6;

        .sidebar-footer {
          background: $skin6;
        }

        .sidebar-footer {
          border-top: 1px solid $border-color;
        }

        .sidebar-footer a,
        .user-profile .profile-text a {
          color: $body-color;
        }

        .sidebar-nav {
          ul {
            background: $skin6;

            .sidebar-item {
              .sidebar-link {
                color: $sidebar-text-dark;
                opacity: 1;
                .feather {
                  fill: rgba(41, 98, 255, 0.1);
                }
                &:hover {
                  .feather {
                    color: $info;
                  }
                }
                i,
                .feather {
                  color: $sidebar-icons-dark;
                }

                &.active {
                  color: $info;

                  i,
                  .feather {
                    color: $info;
                  }
                }
              }

              &.selected > .sidebar-link {
                border-left: 3px solid $info;
                color: $info;
                /*background: rgba(0, 0, 0, 0.04);*/
                opacity: 1;

                i,
                .feather {
                  color: $info;
                }
              }

              .first-level {
                .sidebar-item {
                  .sidebar-link.active {
                    color: $gray-800;

                    i {
                      color: $gray-800;
                    }
                  }

                  &.selected {
                    > .sidebar-link.active {
                      border-left: 0;
                    }
                  }
                }
              }
            }

            .nav-small-cap {
              color: $gray-500;
              opacity: 1;
            }

            .nav-devider {
              background: rgba(120, 130, 140, 0.13);
            }
          }

          .has-arrow {
            &::after {
              border-color: $body-color;
            }
          }
        }
      }
    }
  }

  &[data-layout='horizontal'] {
    /*This is for the logo bg*/
    .topbar {
      &[data-navbarbg='skin1'] {
        @extend %skin_gredient_1;
      }

      &[data-navbarbg='skin2'] {
        @extend %skin_gredient_2;
      }

      &[data-navbarbg='skin3'] {
        @extend %skin_gredient_3;
      }

      &[data-navbarbg='skin4'] {
        @extend %skin_gredient_4;
      }

      &[data-navbarbg='skin5'] {
        @extend %skin_gredient_5;
      }

      &[data-navbarbg='skin6'] {
        @extend %skin_gredient_6;

        .top-navbar .navbar-header {
          .navbar-brand {
            .dark-logo {
              display: inline;
            }

            .light-logo {
              display: none;
            }
          }
        }
      }
    }

    .left-sidebar {
      &[data-sidebarbg='skin1'],
      &[data-sidebarbg='skin2'],
      &[data-sidebarbg='skin3'],
      &[data-sidebarbg='skin4'],
      &[data-sidebarbg='skin5'],
      &[data-sidebarbg='skin6'] {
        .sidebar-nav {
          ul {
            .sidebar-item {
              &.selected > .sidebar-link {
                border-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
