.form-title {
  font-family: Lato;
  font-size: 24px;
  color: #556176;
  font-weight: bold;
}

.form-title-green {
  font-family: Lato;
  font-size: 24px;
  color: #007953;
  font-weight: bold;
}

.form-title-sm-green {
  font-family: Lato;
  font-size: 20px;
  color: #007953;
  font-weight: bold;
}

.form-hr-base {
  margin-top: -5px;
  background-color: #007953;
  height: 1px;
}

.form-element-label {
  font-family: Lato;
  font-size: 16px;
}

.form-buttonCotainer {
  display: flex;
  justify-content: flex-end;
}

.form-buttonCotainer-space-between {
  display: flex;
  justify-content: space-between;
}

.form-btn-primary {
  color: white;
  width: 150px;
  background-color: #007953;
  &:hover {
    color: white;
    background-color: #219963;
  }
}

.form-btn-secundary {
  color: white;
  width: 150px;
  background-color: #009063;
  &:hover {
    color: white;
    background-color: #00a16e;
  }
}

.form-btn-cancel {
  color: black;
  width: 150px;
  background-color: white;
  &:hover {
    color: black;
    background-color: #ebf0f5;
  }
}

.form-btn-danger {
  color: white;
  width: 150px;
  background-color: red;
  &:hover {
    color: white;
    background-color: #ff6464;
  }
}

@media (max-width: 600px) {
  .form-btn-danger {
    height: 30px;
  }
  .form-btn-cancel {
    height: 30px;
  }
  .form-btn-secundary {
    height: 30px;
  }
  .form-btn-primary {
    height: 30px;
  }
}

.form-modal-container {
  width: auto;
  height: auto;
  /* max-width: 100vw; */
}

.form-modal-footer {
  background-color: #f8f9f9;
  display: flex;
  justify-content: center;
}

.form-input-checkbox-container {
  width: 20px;
  height: 20px;
}

.form-validate-error {
  color: #e31d19;
}

.form-hr-base {
  color: green;
  // size: 30;
  /* background-color: green; */
  // height: 40;
}

.form-card-container-margin {
  margin-bottom: -15px;
}

.form-modal-comment {
  min-height: 150px;
  max-height: 300px;
}

.form-test {
  position: absolute;
  z-index: 100;
  top: -1em;
  left: 100%;
  margin: 0em 0em 0em -1em !important;
  background-color: #3a4454;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
