// 
// Button lists margin
// 
.btn-list {
    .btn {
        margin-bottom: 5px;
    }
}

// 
// Circle Buttons
// 
// There are mixins which we have created in mixins/mix_buttons
.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 5px;
    line-height: 30px;
    // Different Size of Buttons
    // #####################################################################
    &.btn-sm {
        width: 35px;
        height: 35px;
        padding: 8px 10px;
        font-size: 14px;
    }
    &.btn-lg {
        width: 50px;
        height: 50px;
        padding: 14px 10px;
        font-size: 18px;
        line-height: 23px;
    }
    &.btn-xl {
        width: 70px;
        height: 70px;
        padding: 14px 15px;
        font-size: 24px;
    }
}

// 
// Rounded Buttons
// 
// There are mixins which we have created in mixins/mix_buttons
.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
    // Different Size of Buttons
    // #####################################################################
    &.btn-xs {
        padding: .25rem .5rem;
        font-size: 10px;
    }
    &.btn-sm {
        padding: .25rem .5rem;
        font-size: 12px;
    }
    &.btn-md {
        padding: 12px 35px;
        font-size: 16px;
    }
    &.btn-lg {
        padding: .75rem 1.5rem;
    }
}

// 
// Extra Small Button
// 
.btn-xs {
    padding: .25rem .5rem;
    font-size: 10px;
}

// 
// Estilos boton generico
//
.button-generic {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: inherit
}

.button-generic-icon-black {
    font-size: 1.5em;
    margin-left: 5px;
    color: black;
}

.button-generic-icon-white {
    font-size: 1.5em;
    margin-left: 5px;
    color: white;
}

.button-generic-cancel {
    border: 0.5px solid #868e96;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    padding-left: 15px;
    background-color: white;
    color: black;
    &:hover {
        color: black;
        background-color: #EBF0F5;
    }
}

.button-generic-task-taken {
    border: 0.5px solid #868e96;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    padding-left: 15px;
    background-color: rgb(255, 255, 153);
    color: black;
    &:hover {
        color: black;
        background-color: rgb(255, 255, 204);
    }
}

.button-generic-task-return {
    border: 0.5px solid #868e96;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    padding-left: 15px;
    background-color: rgb(255, 182, 193);
    color: black;
    &:hover {
        color: black;
        background-color: rgb(255, 204, 229);
    }
    &disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
}

.button-generic-task-save {
    border: 0.5px solid #868e96;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    padding-left: 15px;
    background-color: #007953;
    color: white;
    &:hover {
        color: white;
        background-color: #219963
    }
}

.button-generic-dropdown-task-finish {
    border: 0.5px solid #868e96;
    min-width: 150px;
    height: 50px;
    height: 40px;
    background-color: #009063;
    color: white;
    &:hover {
        color: white;
        background-color: #00a16e
    }
}

.button-generic-text {
    text-align: left;
    margin-left: 20px;
    line-height: 100%;
}

// .button-generic-dorpown-text {
//     width: 100px;
//     white-space: normal;
//     word-break: break-all;
// }
// .dropdown-toggle {
//     width: 100px;
//     white-space: normal;
//     word-break: break-all;
// }