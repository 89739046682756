.view-table-header {
    color: white;
    background-color: #007953;
}

.view-title {
    font-family: Helvetica;
    color: #556176;
    opacity: 90%;
}

.view-label {
    font-family: Helvetica;
    color: #556176;
}

.view-btn-add {
    color: white;
    background-color: #007953 !important;
    &:hover {
        color: white;
        background-color: #219963 !important;
    }
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.view-sidebar-span {
    &:hover {
        color: #13599e;
    }
}

.view-sidebar-active {
    color: #13599e
}